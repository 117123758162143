
import { defineComponent } from "vue";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import View from "@/assets/svg/view.svg?inline";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import Search from "@/assets/svg/search.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import Pagination from "@/components/molecules/Pagination.vue";
import Select from "@/components/atoms/CustomSelect.vue";
import Calendar from "@/components/molecules/Calendar.vue";
import vClickOutside from "click-outside-vue3";

export default defineComponent({
  name: "SkillAchievementLog",
  data() {
    return {
      page: 1,
      anayticsMeta: null,
      anayticsData: {},
      anayticsStickersMeta: null,
      anayticsStickersData: {},
      errorstudents: null,
      isModalDelete: false,
      searchData: "",
      sortData: "",
      dataWhoSetUp: [],
      selectedInstructor: false,
      isCalendarVisible: false,
      filters: "",
      dataAllInstructors: [],
      dataAllStudents: [],
      dataLevels: [],
      dataSkills: [],
      selectedNewDate: null,
      selectedStartDate: null,
      selectedEndDate: null,
      activeTemplate: 1,
    };
  },
  components: {
    AdminPanelNavMenu,
    View,
    Search,
    Select,
    DefaultPopup,
    SortArrow,
    Calendar,
    SelectArrow,
    Pagination,
  },
  watch: {
    searchData() {
      this.getAnalyticsLogActivitys();
      this.page = 1;
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    this.getAnalyticsLogActivitys();
    this.getAnalyticsStickersSent();
    this.getLevels();
    this.getStudents();
    this.getInstructors();
    this.getSkills(1);
  },
  methods: {
    formatDate() {
      const dateObject = new Date(this.inputDate);
      const day = ("0" + dateObject.getDate()).slice(-2);
      const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
      const year = dateObject.getFullYear();
      const hours = ("0" + dateObject.getHours()).slice(-2);
      const minutes = ("0" + dateObject.getMinutes()).slice(-2);
      return `${day}.${month}.${year}, ${hours}:${minutes}`;
    },
    getAnalyticsLogActivitys(params, sort) {
      let searchParam = `?page=${this.page}&search=`;
      if (this.searchData !== "") {
        searchParam = `?page=${this.page}&search=` + this.searchData;
      }
      if (sort === undefined) {
        sort = "id";
      }
      params = searchParam + "&sort=" + sort + this.filters;
      this.$store.dispatch("analytics/getAnalyticsLogActivitys", params).then(
        (res) => {
          this.anayticsData = res.data;
          this.anayticsMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getAnalyticsStickersSent(params, sort) {
      let searchParam = `?page=${this.page}&search=`;
      if (this.searchData !== "") {
        searchParam = `?page=${this.page}&search=` + this.searchData;
      }
      if (sort === undefined) {
        sort = "id";
      }
      params = searchParam + "&sort=" + sort + this.filters;
      this.$store.dispatch("analytics/getAnalyticsStickersSent", params).then(
        (res) => {
          this.anayticsStickersData = res.data;
          this.anayticsStickersMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getInstructors() {
      const params = "?n=100";
      this.$store.dispatch("instructors/getInstructors", params).then(
        (res) => {
          this.dataWhoSetUp = res.data;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getStudents() {
      const params = "?n=200";
      this.$store.dispatch("students/getStudents", params).then(
        (res) => {
          this.dataAllStudents = res.data;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getLevels() {
      this.$store.dispatch("general/getLevels").then(
        (res) => {
          this.dataLevels = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    getSkills(id) {
      this.$store.dispatch("general/getSkillsByLevels", id).then(
        (res) => {
          this.dataSkills = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    viewStudent(id) {
      this.$router.push("/admin/student?student=" + id);
    },
    editstudent(id) {
      this.$router.push("/admin/edit-student?student=" + id);
    },
    openDeletePopup(id) {
      this.studentId = id;
      this.isModalDelete = true;
    },
    handleCloseModalDelete() {
      this.isModalDelete = false;
    },
    deleteStudent() {
      this.$store.dispatch("students/deleteStudent", this.studentId).then(
        (res) => {
          this.getAnalyticsLogActivitys();
          this.isModalDelete = false;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getAnalyticsLogActivitys();
    },
    handleOptionSelected(filterKey, selectedOption, callback) {
      const newFilter = `filter[${filterKey}]=${selectedOption.id}`;
      const regex = new RegExp(`filter\\[${filterKey}\\]=[^&]*`);
      if (selectedOption.id !== undefined) {
        if (regex.test(this.filters)) {
          this.filters = this.filters.replace(regex, newFilter);
        } else {
          this.filters += `${
            this.filters.includes("?") ? "&" : "?"
          }${newFilter}`;
        }
      } else
        this.filters = this.filters
          .replace(regex, "")
          .replace(/&{2,}/g, "&")
          .replace(/[?&]$/, "");
      if (callback) callback(selectedOption.id);
      this.getAnalyticsLogActivitys();
    },
    getSkillsCallback(levelId) {
      this.getSkills(levelId);
    },
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    closeCalendar() {
      this.isCalendarVisible = false;
    },
    selectDateFill(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        this.filters = `&filter[date_range][0]=${this.selectedStartDate}&filter[date_range][1]=${this.selectedEndDate}`;
        this.getAnalyticsLogActivitys();
      }
    },
    resetFilters() {
      window.location.reload();
    },
    togleTabs(id) {
      this.activeTemplate = id;
    },
  },
});
