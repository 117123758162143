import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vShow as _vShow, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "students" }
const _hoisted_2 = { class: "students__block" }
const _hoisted_3 = { class: "students__search-wrap-mobile-container" }
const _hoisted_4 = { class: "students__search-wrap mobile-search" }
const _hoisted_5 = { class: "students__tabs-menu" }
const _hoisted_6 = { class: "main-table__selects" }
const _hoisted_7 = { class: "calendar__data-range-wrap" }
const _hoisted_8 = { class: "students__reset-filters-wrap" }
const _hoisted_9 = {
  key: 0,
  class: "main-table__mobile-wrap"
}
const _hoisted_10 = { class: "main-table__head" }
const _hoisted_11 = { class: "main-table__head-id" }
const _hoisted_12 = { class: "main-table__sort-block" }
const _hoisted_13 = { class: "main-table__head-big-tr" }
const _hoisted_14 = { class: "main-table__sort-block" }
const _hoisted_15 = { class: "main-table__head-big-tr" }
const _hoisted_16 = { class: "main-table__sort-block" }
const _hoisted_17 = { class: "main-table__head-big-tr" }
const _hoisted_18 = { class: "main-table__sort-block" }
const _hoisted_19 = { class: "main-table__head-big-tr" }
const _hoisted_20 = { class: "main-table__sort-block" }
const _hoisted_21 = { class: "main-table__head-big-tr" }
const _hoisted_22 = { class: "main-table__sort-block" }
const _hoisted_23 = { class: "main-table__head-big-tr" }
const _hoisted_24 = { class: "main-table__sort-block" }
const _hoisted_25 = { class: "main-table__body-id" }
const _hoisted_26 = { class: "main-table__body-big-tr" }
const _hoisted_27 = { class: "main-table__body-big-tr" }
const _hoisted_28 = { class: "main-table__body-big-tr" }
const _hoisted_29 = { class: "main-table__body-big-tr" }
const _hoisted_30 = { class: "main-table__body-big-tr" }
const _hoisted_31 = { class: "main-table__body-big-tr" }
const _hoisted_32 = {
  key: 1,
  class: "main-table__mobile-wrap"
}
const _hoisted_33 = { class: "main-table__head" }
const _hoisted_34 = { class: "main-table__head-id" }
const _hoisted_35 = { class: "main-table__sort-block" }
const _hoisted_36 = { class: "main-table__head-big-tr" }
const _hoisted_37 = { class: "main-table__sort-block" }
const _hoisted_38 = { class: "main-table__head-big-tr" }
const _hoisted_39 = { class: "main-table__sort-block" }
const _hoisted_40 = { class: "main-table__head-big-tr" }
const _hoisted_41 = { class: "main-table__sort-block" }
const _hoisted_42 = { class: "main-table__head-big-tr" }
const _hoisted_43 = { class: "main-table__sort-block" }
const _hoisted_44 = { class: "main-table__body-id" }
const _hoisted_45 = { class: "main-table__body-big-tr" }
const _hoisted_46 = { class: "main-table__body-big-tr" }
const _hoisted_47 = { class: "main-table__body-big-tr" }
const _hoisted_48 = { class: "main-table__body-big-tr" }
const _hoisted_49 = { class: "students__popup-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavMenu = _resolveComponent("AdminPanelNavMenu")!
  const _component_Search = _resolveComponent("Search")!
  const _component_SelectArrow = _resolveComponent("SelectArrow")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Select = _resolveComponent("Select")!
  const _component_SortArrow = _resolveComponent("SortArrow")!
  const _component_View = _resolveComponent("View")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdminPanelNavMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Search by name or email",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchData) = $event))
        }, null, 512), [
          [_vModelText, _ctx.searchData]
        ]),
        _createVNode(_component_Search)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.togleTabs(1))),
        class: _normalizeClass(["students__tab", { 'active-tab': _ctx.activeTemplate === 1 }])
      }, " Skills accomplished ", 2),
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.togleTabs(2))),
        class: _normalizeClass(["students__tab", { 'active-tab': _ctx.activeTemplate === 2 }])
      }, " Stickers sent ", 2)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
        _createElementVNode("div", {
          class: "calendar__data-range",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendar && _ctx.toggleCalendar(...args)))
        }, [
          _cache[36] || (_cache[36] = _createTextVNode(" Date range ")),
          _createVNode(_component_SelectArrow, {
            class: _normalizeClass({ isOpenSelectArrow: _ctx.isCalendarVisible })
          }, null, 8, ["class"])
        ]),
        _withDirectives(_createVNode(_component_Calendar, {
          class: "calendar__calendar",
          "select-date-fill": this.selectDateFill,
          "selected-new-date": this.selectedNewDate
        }, null, 8, ["select-date-fill", "selected-new-date"]), [
          [_vShow, _ctx.isCalendarVisible]
        ])
      ])), [
        [_directive_click_outside, _ctx.closeCalendar]
      ]),
      _createVNode(_component_Select, {
        data: _ctx.dataAllStudents,
        "select-name": "Students",
        class: "instructors__second-selects",
        title: 'full_name',
        onOptionSelected: _cache[4] || (_cache[4] = (option) => _ctx.handleOptionSelected('student_id', option))
      }, null, 8, ["data"]),
      _createVNode(_component_Select, {
        data: _ctx.dataLevels,
        "select-name": "Level",
        class: "instructors__second-selects",
        title: 'title',
        onOptionSelected: _cache[5] || (_cache[5] = 
          (option) =>
            _ctx.handleOptionSelected('level_id', option, _ctx.getSkillsCallback)
        )
      }, null, 8, ["data"]),
      _createVNode(_component_Select, {
        data: _ctx.dataSkills,
        "select-name": "Skill",
        title: 'title',
        class: "instructors__select",
        onOptionSelected: _cache[6] || (_cache[6] = (option) => _ctx.handleOptionSelected('skill_id', option))
      }, null, 8, ["data"]),
      _createVNode(_component_Select, {
        data: _ctx.dataWhoSetUp,
        "select-name": "Who set up",
        title: 'full_name',
        class: "instructors__select",
        onOptionSelected: _cache[7] || (_cache[7] = (option) => _ctx.handleOptionSelected('who_set_up', option))
      }, null, 8, ["data"]),
      _createVNode(_component_Select, {
        data: _ctx.dataAllInstructors,
        "select-name": "Instructors",
        class: "instructors__select",
        title: 'full_name',
        onOptionSelected: _cache[8] || (_cache[8] = 
          (option) => _ctx.handleOptionSelected('instructor_id', option)
        )
      }, null, 8, ["data"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", {
        class: "students__reset-filters",
        onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.resetFilters && _ctx.resetFilters(...args)))
      }, " Reset filters ")
    ]),
    (_ctx.activeTemplate === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[37] || (_cache[37] = _createTextVNode(" ID ")),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', 'id')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', '-id'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[38] || (_cache[38] = _createTextVNode(" Date, time ")),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', 'created_at')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[13] || (_cache[13] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', '-created_at'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[39] || (_cache[39] = _createTextVNode(" Student ")),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', 'student')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', '-student'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[40] || (_cache[40] = _createTextVNode(" Level ")),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[16] || (_cache[16] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', 'level')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[17] || (_cache[17] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', '-level'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _cache[41] || (_cache[41] = _createTextVNode(" Skill ")),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', 'skill')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[19] || (_cache[19] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', '-skill'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _cache[42] || (_cache[42] = _createTextVNode(" Skill Progress ")),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[20] || (_cache[20] = ($event: any) => (
                this.getAnalyticsLogActivitys('?search=', 'skill_progress')
              ))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[21] || (_cache[21] = ($event: any) => (
                this.getAnalyticsLogActivitys('?search=', '-skill_progress')
              )),
                  class: "main-table__sort-bottom"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _cache[43] || (_cache[43] = _createTextVNode(" Who set up ")),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[22] || (_cache[22] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', 'who_set_up')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[23] || (_cache[23] = ($event: any) => (this.getAnalyticsLogActivitys('?search=', '-who_set_up'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.anayticsData, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "main-table__body",
              key: item
            }, [
              _createElementVNode("div", _hoisted_25, _toDisplayString(item.id), 1),
              _createElementVNode("div", _hoisted_26, _toDisplayString(item?.passed_at?.split("T")[0]) + ", " + _toDisplayString(item?.passed_at?.substring(11, 16)), 1),
              _createElementVNode("div", _hoisted_27, _toDisplayString(item?.student.full_name), 1),
              _createElementVNode("div", _hoisted_28, _toDisplayString(item?.level?.title), 1),
              _createElementVNode("div", _hoisted_29, _toDisplayString(item?.skill?.skill_order), 1),
              _createElementVNode("div", _hoisted_30, _toDisplayString(item?.skill?.skill_order), 1),
              _createElementVNode("div", _hoisted_31, _toDisplayString(item?.confirmed_by?.full_name), 1),
              _createVNode(_component_View, {
                class: "main-table__svg",
                onClick: ($event: any) => (_ctx.viewStudent(item?.student?.id))
              }, null, 8, ["onClick"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeTemplate === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("div", _hoisted_34, [
              _cache[44] || (_cache[44] = _createTextVNode(" ID ")),
              _createElementVNode("div", _hoisted_35, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[24] || (_cache[24] = ($event: any) => (this.getAnalyticsStickersSent('?search=', 'id')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[25] || (_cache[25] = ($event: any) => (this.getAnalyticsStickersSent('?search=', '-id'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_36, [
              _cache[45] || (_cache[45] = _createTextVNode(" Date, time ")),
              _createElementVNode("div", _hoisted_37, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[26] || (_cache[26] = ($event: any) => (this.getAnalyticsStickersSent('?search=', 'created_at')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[27] || (_cache[27] = ($event: any) => (this.getAnalyticsStickersSent('?search=', '-created_at'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_38, [
              _cache[46] || (_cache[46] = _createTextVNode(" Student ")),
              _createElementVNode("div", _hoisted_39, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[28] || (_cache[28] = ($event: any) => (this.getAnalyticsStickersSent('?search=', 'student_name')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[29] || (_cache[29] = ($event: any) => (this.getAnalyticsStickersSent('?search=', '-student_name'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_40, [
              _cache[47] || (_cache[47] = _createTextVNode(" Stikers ")),
              _createElementVNode("div", _hoisted_41, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[30] || (_cache[30] = ($event: any) => (this.getAnalyticsStickersSent('?search=', 'sticker_title')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[31] || (_cache[31] = ($event: any) => (this.getAnalyticsStickersSent('?search=', '-sticker_title'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_42, [
              _cache[48] || (_cache[48] = _createTextVNode(" Stiker type ")),
              _createElementVNode("div", _hoisted_43, [
                _createVNode(_component_SortArrow, {
                  onClick: _cache[32] || (_cache[32] = ($event: any) => (this.getAnalyticsStickersSent('?search=', 'stiker_type')))
                }),
                _createVNode(_component_SortArrow, {
                  onClick: _cache[33] || (_cache[33] = ($event: any) => (this.getAnalyticsStickersSent('?search=', '-stiker_type'))),
                  class: "main-table__sort-bottom"
                })
              ])
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.anayticsStickersData, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "main-table__body",
              key: item
            }, [
              _createElementVNode("div", _hoisted_44, _toDisplayString(item.id), 1),
              _createElementVNode("div", _hoisted_45, _toDisplayString(item?.date_time), 1),
              _createElementVNode("div", _hoisted_46, _toDisplayString(item?.student), 1),
              _createElementVNode("div", _hoisted_47, _toDisplayString(item?.sticker), 1),
              _createElementVNode("div", _hoisted_48, _toDisplayString(item?.sticker_type), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Pagination, {
      propertyMeta: _ctx.anayticsMeta,
      propertyList: _ctx.anayticsData,
      page: _ctx.page,
      handlePage: _ctx.handlePage
    }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 2,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[49] || (_cache[49] = _createElementVNode("div", { class: "students__popup-title" }, "Are you sure?", -1)),
            _createElementVNode("div", _hoisted_49, [
              _createElementVNode("div", {
                class: "students__popup-button-yes",
                onClick: _cache[34] || (_cache[34] = 
//@ts-ignore
(...args) => (_ctx.deleteStudent && _ctx.deleteStudent(...args)))
              }, "Yes"),
              _createElementVNode("div", {
                class: "students__popup-button-no",
                onClick: _cache[35] || (_cache[35] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}